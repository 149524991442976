import React, { Component } from 'react';
import './layout-builder.css';

import { Helmet } from "react-helmet"
import { loadReCaptcha } from 'react-recaptcha-google'
import CookieConsent from "react-cookie-consent";

import NavigationBar from '../navigation-bar/navigation-bar';
import Footer from '../footer/footer';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Button from '../button/button';

if (typeof document !== "undefined") {
    AOS.init();
}


class LayoutBuilder extends Component<{}, { loading?: string, showLoader?: boolean, buttonTitel?: string, onButtonClicked?: () => void }> {

    private loader?: HTMLDivElement;
    private static that?: LayoutBuilder;

    constructor(p: {}) {
        super(p);
        LayoutBuilder.that = this;
        this.state = {};
    }

    render() {
        return (
            <div className="app" >
                <Helmet>
                    <html lang="de" />
                    <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
                    <link rel="shortcut icon" href="./favicon.ico?" />
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="title" content="Gräfenstein - Skirix" />
                    <meta name="description" content="Wir entwickeln Mobile und Web Apps jeglicher Art. Mit React Native oder Native Android und iOS können wir Ihr Projekt erfolgreich umsetzen." />
                    <meta name="image" content="/dash_title.jpg" />

                    <meta property="og:url" content="http://graefenstein.skirix.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Gräfenstein - Skirix" />
                    <meta property="og:description" content="Wir entwickeln Mobile und Web Apps jeglicher Art. Mit React Native oder Native Android und iOS können wir Ihr Projekt erfolgreich umsetzen." />
                    <meta property="og:image" content="/dash_title.jpg" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Gräfenstein - Skirix" />
                    <meta name="twitter:description" content="Wir entwickeln Mobile und Web Apps jeglicher Art. Mit React Native oder Native Android und iOS können wir Ihr Projekt erfolgreich umsetzen." />
                    <meta name="twitter:image" content="/dash_title.jpg" />

                    <link rel="apple-touch-icon" href="/favicon.ico?" />



                    <link rel="manifest" href="/manifest.json" />

                    <title>Gräfenstein - Skirix - Mobile App Entwicklung</title>
                </Helmet>
                <NavigationBar />
                <div className="layout-content">
                    {this.props.children}
                </div>
                <Footer />
                <div ref={(d) => { if (d) this.loader = d; }} className="loadingview-global">
                    <div className="solid-background" >
                        {this.state.showLoader != false &&
                            <div className="loader">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </div>
                        }
                        <p>{this.state.loading || "Loading..."}</p>
                        <Button title={this.state.buttonTitel || "Abbrechen"} onClick={this.state.onButtonClicked} />
                    </div>
                </div>
                <CookieConsent
                    buttonClasses={"button"}
                    buttonText={"Verstanden"}>
                    Diese Website benutzt Cookies. Wenn du die Website weiter nutzt, gehen wir von deinem Einverständnis aus. <span style={{ fontSize: 10 }}>Mehr Informationen unter <a className="cookiebanner-link" href="/privacy-policy">Datenschutz</a>.</span>
                </CookieConsent>
            </div>
        );
    }

    componentDidMount() {
        loadReCaptcha();
    }

    public static showLoader(message?: string, loader?: boolean, onButtonClicked?: () => void, buttonTitel?: string) {
        if (LayoutBuilder.that && LayoutBuilder.that.loader) {
            LayoutBuilder.that.setState({ loading: message, showLoader: loader, onButtonClicked: onButtonClicked, buttonTitel: buttonTitel });
            LayoutBuilder.that.loader.style.display = "flex";
        }
    }

    public static hideLoader() {
        if (LayoutBuilder.that && LayoutBuilder.that.loader) {
            LayoutBuilder.that.loader.style.display = "none";
            LayoutBuilder.that.setState({ loading: undefined, showLoader: undefined, buttonTitel: undefined });
        }
    }
}

export default LayoutBuilder;
