import React, { Component } from 'react';
import './certificates-section.css';


export default class CertificatesSection extends Component<{}, {}> {

    render() {
        return (
            <div className="default-section-background certificates-section-background">
                <div className="default-section certificates-section" >
                    <div className="certificate-container">
                        <a href="https://www.provenexpert.com/skirix/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget" title="Kundenbewertungen &amp; Erfahrungen zu Skirix. Mehr Infos anzeigen." target="_blank" style={{ textDecoration: "none" }}>
                            <img src="https://images.provenexpert.com/da/04/dbfdc0fd15bc9eb1a955ca42c335/widget_landscape_215_de_0.png" alt="Erfahrungen &amp; Bewertungen zu Skirix" width="215" height="179" style={{ border: 0 }} />
                        </a>
                    </div>
                    <div className="certificate-container">
                        <a href="https://app-entwickler-verzeichnis.de/" title="Mobile-Developer" style={{ border: "none" }} target="_blank">
                            <img src="https://app-entwickler-verzeichnis.de/images/corporate/button-qualifiziert.png" alt="Mobile-Developer" width="235" height="157" />
                        </a>
                    </div>
                    <div className="certificate-container">
                        <a href="https://www.btrusted.de/profile/standalone/6283500">
                            <img src="https://www.btrusted.de/seal/imageweb/6283500.png?size=1&amp;type=1" alt="btrusted-Siegel" style={{ border: "0" }} />
                        </a>
                    </div>
                    <div className="certificate-container">
                        <a href="https://siwecos.de/scanned-by-siwecos/?data-siwecos=www.graefenstein.skirix.com" target="_blank">
                            <img width="150" height="58" src="https://siegel.siwecos.de/www.graefenstein.skirix.com/d.m.y.svg"  alt="Siweco - Siegel"/>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}