import React, { Component } from 'react';
import './personal-skills-section.css';

export default class PersonalSkillsSection extends Component<{}, {}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <div className="default-section-background personal-skills-section-background">
                <div className="default-section personal-skills-section" >
                    <div className="personal-skills-group" >
                        <h5>PERSÖHNLICHE KOMPETENZ</h5>
                        <ul>
                            <li>Zuverlässigkeit</li>
                            <li>Lernbereitschaft</li>
                            <li>Selbstdisziplin</li>
                            <li>Engagement</li>
                            <li>Ausdauer</li>
                            <li>Belastbarkeit</li>
                            <li>Verantwortungs­&shy;bereitschaft</li>
                            <li>Sorgfalt</li>
                            <li>Kreativität</li>
                        </ul>
                    </div>
                    <div className="personal-skills-group">
                        <h5>SOZIALE KOMPETENZ</h5>
                        <ul>
                            <li>Empathie</li>
                            <li>Teamfähigkeit</li>
                            <li>Kommunikative Kompetenz</li>
                            <li>Höflichkeit</li>
                            <li>Konfliktfähigkeit</li>
                            <li>Vertrauens&shy;würdigkeit</li>
                            <li>Kritikfähigkeit</li>
                        </ul>
                    </div>
                </div>
            </div >
        )
    }
}