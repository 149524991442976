import React, { Component, CSSProperties } from 'react';
import './footer.css';
import FooterContainerTitle from '../footer-container-title/footer-container-title';
import OrderPreview from '../../components/order-preview/order-preview';
import FooterBottomLine from '../footer-bottom-line/footer-bottom-line';
import InteractiveIcon from '../../components/interactive-icon/interactive-icon';
import ArrowDownDouble from '../../images/arrow-down-double.png';
import { Projects } from '../../data/projects';
import { getProjectId } from '../../utils/id';
import { Twitter, Facebook } from 'react-social-sharing'

export default class Footer extends Component<{}, {}> {

    render() {
        return (
            <footer className="footer">
                <div className="footer-padding">
                    <div className="footer-inner-container">
                        <div className="footer-container">
                            <FooterContainerTitle title="Sitemap" />
                            <div className="footer-list">
                                <a href="/">Portfolio</a>
                                <a href="/contact">Kontakt</a>
                                <a href="/about">Über mich</a>
                                <a href="/impressum">Impressum</a>
                                <a href="/privacy-policy">Datenschutz</a>
                            </div>
                        </div>
                        {/*<div className="footer-container">
                            <FooterContainerTitle title="Partner" />
                            <div className="footer-list">
                                <a href="/"><img src={Logo} ></img></a>
                                <a href="#"><img src={Logo} ></img></a>
                                <a href="#"><img src={Logo} ></img></a>
                            </div>
        </div>*/}
                        <div className="footer-container">
                            <FooterContainerTitle title="Letzte Projekte" />
                            <div className="footer-list">
                                {
                                    this.renderLastProjects()
                                }
                            </div>
                        </div>
                        <div className="footer-container">
                            <FooterContainerTitle title="Portfolio" />
                            <div className="footer-collection" >
                                {
                                    this.renderProjectsPreview()
                                }
                            </div>
                        </div>
                        <div className="footer-container right-footer-container">
                            <FooterContainerTitle title="Share" />
                            <div className="list-share">
                                <Twitter link="https://graefenstein.skirix.com" />
                                <Facebook link="https://graefenstein.skirix.com" />
                                <a href="https://www.provenexpert.com/skirix/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget" title="Kundenbewertungen &amp; Erfahrungen zu Skirix. Mehr Infos anzeigen." target="_blank" style={{ textDecoration: "none", marginTop: 16 }}>
                                    <img src="https://images.provenexpert.com/da/04/dbfdc0fd15bc9eb1a955ca42c335/widget_portrait_180_de_0.png" alt="Erfahrungen &amp; Bewertungen zu Skirix" width="180" height="216" style={{ border: "0" }} />
                                </a>
                            </div>
                        </div>
                        <div className="footer-up-arrow">
                            <InteractiveIcon src={ArrowDownDouble} iconStyle={style.arrowUp} href="#" alt="Arrow to scroll up" />
                        </div>
                    </div>
                </div>
                <FooterBottomLine />
            </footer>
        )
    }

    renderProjectsPreview(): Array<JSX.Element> {
        let items = new Array();
        for (let i = 0; i < Projects.length; i++) {
            items.push(<a key={"project_preview_" + Projects[i].title + "_" + i} href={"/#" + getProjectId(Projects[i])}><div className="collection-item" style={{ backgroundImage: `url(${Projects[i].image})` }}></div></a>);
        }
        return items;
    }

    renderLastProjects(): Array<OrderPreview> {
        let orderedProjects = Projects.slice().sort((a, b) => {
            return b.date.getTime() - a.date.getTime();
        });
        let items = new Array();
        for (let i = 0; i < Math.min(orderedProjects.length, 4); i++) {
            items.push(<OrderPreview key={"order_preview_" + orderedProjects[i].title + "_" + i} project={orderedProjects[i]} />);
        }
        return items;
    }
}

const style: { [key: string]: CSSProperties } = {
    arrowUp: {
        transform: 'rotate(180deg)',
    },
}