import React, { Component, CSSProperties } from 'react';
import './button.css';

interface Props {
    title: string;
    style?: CSSProperties;
    href?: string;
    onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
}

export default class Button extends Component<Props, {}> {

    render() {
        const { style, href, title, onClick } = this.props;
        return (
            <button className='button' style={style} onClick={onClick ? onClick : () => href && window.location.replace(href)}>
                {title}
            </button>
        );
    }
}