import React, { Component } from 'react';
import { Project } from '../../data/projects';
import './order-preview.css';
import { getProjectId } from '../../utils/id';

interface OrderPreviewProps {
    project: Project;
}

export default class OrderPreview extends Component<OrderPreviewProps, {}> {

    render() {
        const { project } = this.props;
        return (
            <div className="order-preview">
                <a href={"/#" + getProjectId(project)}>
                    <div className="order-date">
                        <p>{project.date.getFullYear().toString(10).substring(2)}</p>
                        <p className="order-month">{project.date.toLocaleString("en", { month: "long" }).substring(0, 3)}</p>
                    </div>
                    <div className="order-description" >
                        <p>{project.title}</p>
                        <p>{project.subTitle}</p>
                    </div>
                </a>
            </div>
        )
    }



}