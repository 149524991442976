import React, { Component } from 'react';
import './footer-bottom-line.css';

import Twitter from '../../images/twitter.png';
import Facebook from '../../images/facebook.png';
import Instagram from '../../images/instagram.png';

export default class FooterBottomLine extends Component<{}, {}> {

    render() {
        return (
            <footer className="footer-bottom-line">
                <div className="footer-bottom-line-inner-container" >
                    <p>Copyright 2019 © by Artur Gräfenstein</p>
                    <div className="pe-richsnippets" style={{ marginTop: 16 }}></div>
                    <div className="social-icons-box" >
                        <a href="https://twitter.com/Skirix_com" target="_blank">
                            <img src={Twitter} alt="Twitter"></img>
                        </a>
                        <a href="https://www.facebook.com/Skirix-355621925195508" target="_blank">
                            <img src={Facebook} alt="Facebook" ></img>
                        </a>
                        <a href="https://www.instagram.com/skirix_com/" target="_blank">
                            <img src={Instagram} alt="Instagram" ></img>
                        </a>
                    </div>
                </div>
            </footer>
        )
    }

    componentDidMount(){
        this.loadPERichSnippet();
    }

    loadPERichSnippet() {
        var urlCss = 'https://www.provenexpert.com/css/google-stars.css';
        if (urlCss !== '') {
            var $css = document.createElement('link');

            $css.type = 'text/css';
            $css.rel = 'stylesheet';
            $css.href = urlCss;
            document.getElementsByTagName('head')[0].appendChild($css);
        }
        document.getElementsByClassName('pe-richsnippets')[0].innerHTML = '<!-- 3600/3600 --><a id="pe_rating" xmlns:v="http://rdf.data-vocabulary.org/#" typeof="v:Review-aggregate" title="Kundenbewertungen &amp; Erfahrungen zu Skirix. Mehr Infos anzeigen." target="_blank" href="https://www.provenexpert.com/skirix/" class="pe_g pe_l"> <span id="pe_stars"> <span style="width: 100%;"> <span property="v:itemreviewed">Skirix</span> hat <span rel="v:rating"><span property="v:value">5,00</span> von <span property="v:best">5</span> Sternen |</span> </span> </span> <span class="pe_u"> <span property="v:count">1</span> Bewertung auf ProvenExpert.com </span> </a> ';
    }
}