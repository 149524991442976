import React, { Component, CSSProperties } from 'react';
import './navigation-bar.css';
import NavigationBarLabel from '../navigation-bar-label/navigation-bar-label';
import Button from '../button/button';
import Logo from '../logo/logo';

const SCROLL_OFFSET = 150;
const DEBOUNCING_LIMIT = 50;

export default class NavigationBar extends Component<{}, {}> {

    private debouncePosition: number = 0;

    render() {
        return (
            <div className='navigation-bar' >
                <div className='inner-container' >
                    <div className='splitt-navigation-bar' id="splitt-navbar">
                        <div className='navigation-bar-logo' >
                            <Logo />
                        </div>
                        <div id="navbar-buttons-container" className='navigation-bar-buttons'  >
                            <NavigationBarLabel title="Portfolio" href="/" />
                            <NavigationBarLabel title="Über mich"  href="/about"/>
                            <Button title='Kontakt' style={style.button}  href="/contact"/>
                            <a href="javascript:void(0);" className="icon blue" onClick={this.toggleNavBar} >
                                <i className="fa fa-bars"></i>
                            </a>
                        </div>
                    </div>
                    <div id="collapsible-menue" className="collapsible-menue" >
                        <a href="/" className="collapsible-menue-item">Portfolio</a>
                        <a href="/about" className="collapsible-menue-item">Über mich</a>
                        <a href="/contact" className="collapsible-menue-item">Kontakt</a>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.addEventListener("scroll", this.onWindowScrolled, false);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onWindowScrolled, false);
    }

    onWindowScrolled = (event: Event) => {
        let currentScrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
        if (Math.abs(this.debouncePosition - currentScrollPosition) > DEBOUNCING_LIMIT) {
            if (currentScrollPosition > SCROLL_OFFSET) {
                (document.querySelector("#splitt-navbar")! as HTMLElement).classList.add("scrolled-nav-bar");
            } else {
                (document.querySelector("#splitt-navbar")! as HTMLElement).classList.remove("scrolled-nav-bar");
            }
            this.debouncePosition = currentScrollPosition
        }
    }

    toggleNavBar() {
        let collapsibleMenue = document.getElementById("collapsible-menue");
        if (!collapsibleMenue) return;
        if (collapsibleMenue.style.height && (parseInt(collapsibleMenue.style.height, 10) > 0 || collapsibleMenue.style.height == "auto")) {
            collapsibleMenue.style.height = "0px";
        } else {
            collapsibleMenue.style.height = "auto";
        }
    }
}

const style: { [key: string]: CSSProperties } = {
    button: {
        alignSelf: 'center',
        marginLeft: 'var(--margin-medium)',
    }
}
