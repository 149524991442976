interface Skill {
    skill: string;
    percentage: number;
}

export const Skills: Skill[] = [
    {
        skill: "React Native",
        percentage: 90,
    }, {
        skill: "JavaScript",
        percentage: 90,
    }, {
        skill: "Android",
        percentage: 90,
    }, {
        skill: "Java",
        percentage: 90,
    }, {
        skill: "TypeScript",
        percentage: 80,
    }, {
        skill: "XML / JSON",
        percentage: 90,
    }, {
        skill: "Swift",
        percentage: 65,
    }, {
        skill: "CSS / HTML",
        percentage: 70,
    }, {
        skill: "Android Auto",
        percentage: 50,
    }, {
        skill: "Datenbank / MySQL",
        percentage: 60,
    }, {
        skill: "API / REST / GraphQL",
        percentage: 65,
    }, {
        skill: "AutoIt",
        percentage: 65,
    }, {
        skill: "Objective-C",
        percentage: 45,
    }, {
        skill: "C#",
        percentage: 30,
    }, {
        skill: "Unity 3D",
        percentage: 50,
    }, {
        skill: "Eclipse, intellij",
        percentage: 85,
    }, {
        skill: "Visual Studio Code",
        percentage: 80,
    }, {
        skill: "Xcode",
        percentage: 60,
    }, {
        skill: "GIT",
        percentage: 70,
    }, {
        skill: "JIRA / Confluence",
        percentage: 70,
    }
]