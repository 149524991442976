import React, { Component, CSSProperties } from 'react';
import './interactive-icon.css';

interface IconProps {
    src: string,
    alt: string;
    style?: CSSProperties,
    iconStyle?: CSSProperties,
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    href?: string;
    target?: string;
    pointer?: boolean;
    bigIcon?: boolean;
}

export default class InteractiveIcon extends Component<IconProps, {}> {

    render() {
        const { href, target, onClick, style, src, iconStyle, pointer, bigIcon, alt } = this.props;
        return (
            <a href={href} target={target} onClick={onClick} style={{ ...style, cursor: (onClick || href || pointer) ? "pointer" : undefined }} className={"interactive-icon"} >
                <img className={"icon-image" + (bigIcon ? " icon-image-big" : "")} src={src} style={iconStyle} alt={alt} />
            </a>
        )
    }
}