import React, { Component, CSSProperties } from 'react';
import './vertical-view-pager.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import InteractiveIcon from '../interactive-icon/interactive-icon';
import ArrowDown from '../../images/arrow-down.png';
import ArrowUp from '../../images/arrow-up.png';

const TRANSITION_DURATION = 700;
const SLIDER_SETTINGS = {
    dots: true,
    infinite: true,
    autoplaySpeed: 5000,
    speed: TRANSITION_DURATION,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
    focusOnSelect: false,
    vertical: true,
    verticalSwiping: true,
    pauseOnDotsHover: true,
    pauseOnHover: true
};

interface VerticalViewPagerProps {
    id?: string;
    autoPlay?: boolean;
    beforeChange?: (items: number) => void;
    afterChange?: (items: number) => void;
}

export default class VerticalViewPager extends Component<VerticalViewPagerProps, {}> {

    private slider?: Slider;
    private startSlickPlayTimer?: NodeJS.Timeout;
    constructor(props: {}) {
        super(props);
    }

    componentDidUpdate() {
        if (this.props.autoPlay && this.slider) {
            this.slider.slickPlay();
        }
        if (!this.props.autoPlay) {
            this.startSlickPlayTimer && clearTimeout(this.startSlickPlayTimer);
        }
    }

    render() {
        const { afterChange, beforeChange, autoPlay } = this.props
        return (
            <div className="vertical-view-pager-container" id={this.props.id} >
                <div className="slider-dots-container" >
                    <div className="slider-container">
                        <Slider ref={(slider: Slider) => { this.slider = slider }} {...SLIDER_SETTINGS} afterChange={afterChange} beforeChange={beforeChange} autoplay={autoPlay}>
                            {this.props.children}
                        </Slider>
                        <div className="vertical-carousel-arrows-container">
                            <div className="vertical-carousel-arrow" onClick={this.onNextClicked}  ><InteractiveIcon src={ArrowDown} iconStyle={style.arrowDown} pointer alt="Arrow to show previus page" /></div>
                            <div className="vertical-carousel-arrow" onClick={this.onPreviusClicked} ><InteractiveIcon src={ArrowUp} iconStyle={style.arrowUp} pointer alt="Arrow to show next page" /></div>
                        </div>
                    </div>
                    <div className="dots-container"></div>
                </div>
            </div>
        )
    }

    onPreviusClicked = () => {
        this.slider && this.slider.slickPrev();
        this.pauseSlickForSomeTime();
    }

    onNextClicked = () => {
        this.slider && this.slider.slickNext();
        this.pauseSlickForSomeTime();
    }

    pauseSlickForSomeTime() {
        this.slider && this.slider.slickPause();
        this.startSlickPlayTimer && clearTimeout(this.startSlickPlayTimer);
        this.startSlickPlayTimer = setTimeout(() => {
            this.slider && this.slider.slickPlay();
        }, 10000);
    }
}

const style: { [key: string]: CSSProperties } = {
    arrowDown: {
        transform: 'translateY(1px)',
    },
    arrowUp: {
        transform: 'translateY(-1px)',
    }
}