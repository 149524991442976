import React, { Component } from 'react';
import './my-skills-pager.css';
import VerticalViewPager from '../vertical-view-pager/vertical-view-pager';
import { ID } from '../../utils/id';
import { Skills } from '../../data/skills';

const DEBOUNCING_LIMIT = 50;

export default class SkillsSection extends Component<{}, { autoPlay: boolean }> {

    private pagerId: string;
    private debouncePosition: number = 0;

    constructor(props: {}) {
        super(props);
        this.state = {
            autoPlay: false,
        }
        this.pagerId = "skills-pager" + ID();
    }

    render() {
        return (
            <VerticalViewPager afterChange={this.afterChange} id={this.pagerId} autoPlay={this.state.autoPlay}>
                {
                    this.getSkillsElelemts()
                }
            </VerticalViewPager>
        )
    }

    componentDidMount() {
        window.addEventListener("scroll", this.onWindowScrolled, false);
        if (this.isScrolledIntoView((document.querySelector("#" + this.pagerId)! as HTMLElement))) {
            this.afterChange();
            !this.state.autoPlay && this.setState({ autoPlay: true });
        } else {
            this.resetSkillBars();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onWindowScrolled, false);
    }

    getSkillsElelemts() {
        return Skills.map((s, i) => {
            return (<div key={"id-" + s.skill + "-" + i} className="vertical-pager-child" >
                <div className="skill-bar-background">
                    <div className="skill-bar" data-skill-percent={s.percentage} >
                        <p>{s.skill}</p>
                    </div>
                </div>
            </div >)
        })
    }

    onWindowScrolled = () => {
        let currentScrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
        if (Math.abs(this.debouncePosition - currentScrollPosition) > DEBOUNCING_LIMIT) {
            if (this.isScrolledIntoView((document.querySelector("#" + this.pagerId)! as HTMLElement))) {
                this.afterChange();
                !this.state.autoPlay && this.setState({ autoPlay: true });
            } else {
                this.resetSkillBars();
                this.state.autoPlay && this.setState({ autoPlay: false });
            }
            this.debouncePosition = currentScrollPosition
        }
    }

    afterChange = () => {
        let slides = document.getElementsByClassName("slick-slide");
        for (let i = 0; i < slides.length; i++) {
            let slide = slides[i] as HTMLDivElement;
            let hidden = slide.getAttribute("aria-hidden");
            let innerbar = document.querySelectorAll('.slick-slide .skill-bar');
            if (innerbar[i]) {
                if (hidden == 'false') {
                    (innerbar[i] as HTMLElement).style.transition = "2s";
                    (innerbar[i] as HTMLElement).style.width = (innerbar[i] as HTMLElement).dataset.skillPercent + "%" || "0%";
                } else {
                    (innerbar[i] as HTMLElement).style.transition = "0s";
                    (innerbar[i] as HTMLElement).style.width = "0%";
                }
            }
        }
    }

    resetSkillBars() {
        let bars = document.querySelectorAll('.slick-slide .skill-bar');
        for (let i = 0; i < bars.length; i++) {
            let bar = bars[i] as HTMLDivElement;
            bar.style.transition = "0s";
            bar.style.width = "0%";
        }
    }

    isScrolledIntoView(el: HTMLElement) {
        if (!el) {
            return true;
        }
        var elemTop = el.getBoundingClientRect().top;
        var elemBottom = el.getBoundingClientRect().bottom;
        var isVisible = ((elemTop >= 0) && (elemTop <= window.innerHeight)) || ((elemTop < 0) && (elemBottom > 0));
        return isVisible;
    }
}