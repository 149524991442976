import React, { Component } from 'react';
import './footer-container-title.css';

export interface FooterContainerTitleProps {
    title: string;
}

export default class FooterContainerTitle extends Component<FooterContainerTitleProps, {}> {

    render() {
        return (
            <h4 className="footer-container-title">{this.props.title}</h4>
        )
    }
}