import React, { Component } from 'react';
import './skills-section.css';
import MySkillsPager from '../my-skills-pager/my-skills-pager';
import { Parallax, Background } from 'react-parallax';

export default class SkillsSection extends Component<{}, {}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <Parallax
                bgImage={require('../../images/mountains-background.jpg')}
                bgImageAlt="Mountains"
                strength={800}
            >
                <div className="default-section-background skills-section-background" id="my-skills-section">
                    <div className="default-section skills-section" >
                        <div className="skills-bars" >
                            <h1>My Skills</h1>
                            <MySkillsPager />
                        </div>
                        <div className="skills-description">
                            <p>
                                Über viele Jahre konnte ich mit den unterschiedlichsten Technologien arbeiten oder oft einfach in der Freizeit entwickeln.
                                Ich bin immer erfreut neue Sprachen zu lernen, um damit Projekte um zu setzen.
                                <br />
                                <br />
                                Ein paar der wichtigsten Technologien habe ich für Sie aufgelistet.
                                Meine aktuellsten Projekte waren in React Native(Android/iOS) und Native Android.
                        </p>
                        </div>
                    </div>
                </div >
            </Parallax >
        )
    }
}