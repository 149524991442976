import React, { Component } from 'react';
import './navigation-bar-label.css';


interface Props {
    title: string;
    href?: string;
}

export default class NavigationBarLabel extends Component<Props, {}> {

    render() {
        const { title, href } = this.props;
        return (
            <a href={href} className='navigation-bar-label'>
                <span className="button-underlined"> {title}</span>
            </a>
        );
    }

}