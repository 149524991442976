import React, { Component } from 'react';
import LayoutBuilder from '../components/layout-builder/layout-builder';
import Header from '../components/header/header';
import MeSection from '../components/me-section/me-section';
import SkillsSection from '../components/skills-section/skills-section';
import PersonalSkillsSection from '../components/personal-skills-section/personal-skills-section';
import CertificatesSection from '../components/certificates-section/certificates-section';
import ContactSection from '../components/contact-section/contact-section';

const MeSectionId = "about-me-section";

export default class About extends Component<{}, {}> {

    render() {
        return (
            <LayoutBuilder >
                <Header title="Ein Computerprogramm macht das,\n was Sie ihm sagen, nicht das, was Sie wollen." scrollToId={MeSectionId} />
                <MeSection id={MeSectionId} />
                <SkillsSection />
                <PersonalSkillsSection />
                <CertificatesSection />
                <ContactSection />
            </LayoutBuilder>
        );
    }
}