import BPMTitleImage from '../images/bpm_title.jpg';
import SuperphoneTitleImage from '../images/superphone_title.jpg';
import DashTitleImage from '../images/dash_title.jpg';
import JoinrTitleImage from '../images/joinr_title.jpg';
import FullCircleTitleImage from '../images/fullcircle_titlem.jpg';

//BPM
import BPMScreen1 from '../images/bpm_screen_1.jpg';
import BPMScreen2 from '../images/bpm_screen_2.jpg';
import BPMScreen3 from '../images/bpm_screen_3.jpg';
import BPMScreen4 from '../images/bpm_screen_4.jpg';

//Superphone 
import SuperphoneScreen1 from '../images/superphone_screen_1.jpg';
import SuperphoneScreen2 from '../images/superphone_screen_2.jpg';
import SuperphoneScreen3 from '../images/superphone_screen_3.jpg';
import SuperphoneScreen4 from '../images/superphone_screen_4.jpg';

//DashRadio
import DashRadioScreen1 from '../images/dashradio_screen_1.png';
import DashRadioScreen2 from '../images/dashradio_screen_2.png';
import DashRadioScreen3 from '../images/dashradio_screen_3.png';
import DashRadioScreen4 from '../images/dashradio_screen_4.png';

//FullCircle
import FullCircleScreen1 from '../images/fullcircle_screen_1.jpg';
import FullCircleScreen2 from '../images/fullcircle_screen_2.jpg';

//Joinr
import JoinrScreen1 from '../images/joinr_screen_1.png';
import JoinrScreen2 from '../images/joinr_screen_2.png';
import JoinrScreen3 from '../images/joinr_screen_3.png';
import JoinrScreen4 from '../images/joinr_screen_4.png';


export interface Screen {
    image: string;
    description: string;
}

export interface Project {
    title: string;
    subTitle: string;
    image: string;
    description: string;
    technicalInfo: string;
    screens?: Screen[];
    date: Date;
}


export const Projects: Project[] = [
    {
        title: "BPM - Supreme",
        subTitle: "The Only Music Source DJs and Producers Need",
        image: BPMTitleImage,
        description: "Die BPM-Supreme App ist ein DJ Pool mit einer großen Sammlung von Songs jeglicher Art. Zu jeden Song gibt es oft mehrere Versionen z. B. clean, dirty, remix. Die Songs können einfach durchsucht, nach vielen Kriterien gefiltert und sortiert werden, sodass der richtige Song schnell gefunden wird. Mit einem klick kann die passende Version zur Crate hinzugefügt werden, wo sie immer schnell abrufbereit steht.",
        technicalInfo: `Ich habe das Projekt aufgesetzt, Git (Bitbucket), Typescript und das React-Native Framework konfiguriert und die Projektstruktur erstellt. Bugsnag hab ich für das Bug Tracking implementiert. Die App wurde an eine REST API angebunden. Entwicklung der Generischen views und des Frontends wurden von mir übernommen. Gearbeitet wurde nach dem Git-Flow-Prinzip.

        Tools:
        Visual Studio Code, Android Studio, xCode, Objective-C, React-Native, JavaScript, Typescript, nodeJS, Android SDK, Pods, Jira, Bitbucket, Facebook SDK, Bugsnag, Rest API, Video Player

        Etwa 80%​ der App wurde von mir entwickelt.

        <a title="AppStore - BPM-Supreme">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fbpm-supreme%2Fid1435624754%3Fmt%3D8</a>
        <a title="PlayStore - BPM-Supreme">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.bpmmobile</a>
        <a title="Website - BPM-Supreme">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fwww.bpmsupreme.com</a>
        <a title="Petesso - BPM-Supreme">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fpetesso.de%2Fwork%2Fbpm-supreme%2F</a>
        `,
        screens: [{
            image: BPMScreen1,
            description: "Sieh dir die beliebtesten Songs nach Genre geordnet an."
        }, {
            image: BPMScreen2,
            description: "Stöbre einfach durch alle Songs."
        }, {
            image: BPMScreen3,
            description: "Mit Hilfe der umfangreichen Suche, findest du jeden Song sehr schnell."
        }, {
            image: BPMScreen4,
            description: "Ein schicker Player bietet dir alle Funktionen die du brauchst."
        }],
        date: new Date(2018, 8),
    },
    {
        title: "Superphone",
        subTitle: "Powerful Text Marketing at Scale",
        image: SuperphoneTitleImage,
        description: "Um einen Überblick der Kontakte zu behalten wurde SuperPhone entwickelt. Es hilft dir bei deinen Kontakten und hat einige Funktionen und Automationen die vieles leichter machen. So hast du alle deine Kontakte z.b. Aus Facebook, Twitter oder dem Telefonbuch in einer App und kannst sowohl deine Geschäftsbeziehungen als auch die Beziehung zu deinen Fans professionell pflegen.",
        technicalInfo: `Die App wurde an eine QraphQL API angebunden. Entwicklung der Generischen Views und des Frontends wurden von mir übernommen. Für die großen Datenmengen wurde Realm genutzt. Bugsnag wurde für das Bug Tracking implementiert. Ich habe mehrere Libraries für das React-Native Framework (weiter-)entwickelt die für dieses Projekt gebraucht wurden.

        Tools:
        Visual Studio Code, Android Studio, xCode, Objective-C, React-Native, JavaScript, Typescript, nodeJS, Android SDK, Pods, Jira, Github, Facebook SDK, Twitter SDK, Glympse SDK, Fabric, Crashlytics, Answers, Bugsnag, GraphQL API, Google Maps, Realm, Socket.IO
        
        Über 50% der App wurden von mir entwickelt.

        <a title="AppStore - SuperPhone">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fsuperphone%2Fid1144753815%3Fmt%3D8</a>
        <a title="PlayStore - SuperPhone">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dio.superphone.app</a>
        <a title="Website - SuperPhone">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fwww.superphone.io</a>
        <a title="Petesso - SuperPhone">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fpetesso.de%2Fwork%2Fsuperphone%2F</a>
        `,
        screens: [{
            image: SuperphoneScreen2,
            description: "Erreiche alle deine Kontakte schnell und einfach über den Chat."
        }, {
            image: SuperphoneScreen1,
            description: "Konfiguriere die 'Never Lose Touch' Funktion um mit wichtigen Leuten immer im in Kontakt zu bleiben."
        }, {
            image: SuperphoneScreen3,
            description: "Plane wichtige Nachrichten im Voraus."
        }, {
            image: SuperphoneScreen4,
            description: "Einige Statistiken zu deinem Account."
        }],
        date: new Date(2017, 10),
    },
    {
        title: "Dash Radio",
        subTitle: "A digital radio broadcasting platform",
        image: DashTitleImage,
        description: "Mit der Dash Radio App kann man Kostenlos Musik hören. Dort sind viele Sender von Bekannten DJs. Der Stream läuft über die Internetverbindung. Eine Favoritenliste der Stations kann erstellt und bearbeitet werden. Ebenso gibt es die Möglichkeit Songs zu Favorisieren. Diese werden dann in einer separaten Liste gespeichert.",
        technicalInfo: `Komplette Neuentwicklung der alten Android App. Anbindung an die API sowie Entwicklung des gesamten Frontends. Facebook und Twitter SDK wurden implementiert. Crashlytics wurde für das Bug Tracking verwendet.
        
        Tools:
        Android Studio, Java, Android SDK, Jira, Bitbucket, Facebook SDK, Twitter SDK, Fabric, Crashlytics, Answers, API über Big Data XML File fetch

        Die zweite und dritte Version der Android App wurden ​etwa zu 50%​ von mir entwickelt.
        
        <a title="AppStore - Dash Radio">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fdash-radio%2Fid907383542%3Fmt%3D8</a>
        <a title="PlayStore - Dash Radio">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.dashradio.dash%26hl%3Dde</a>
        <a title="Website - Dash Radio">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fdashradio.com</a>
        <a title="Petesso - Dash Radio">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fpetesso.de%2Fwork%2Fdash-radio</a>
        `,
        screens: [{
            image: DashRadioScreen1,
            description: "Eine Übersicht aller deiner Favorisierten Radio Sender."
        },  {
            image: DashRadioScreen3,
            description: "Durchsuche alle Radio Sender nach Genre."
        }, {
            image: DashRadioScreen2,
            description: "Verbinde die App zu einem beliebigen Gerät und genieße mit Freunden zusammen deine Musik."
        },{
            image: DashRadioScreen4,
            description: "Navigiere zum nächsten Sender oder schau in die Lyriks, alles im Player möglich."
        }],
        date: new Date(2015, 8),
    },
    {
        title: "Full Circle",
        subTitle: "Sell quality auto goods",
        image: FullCircleTitleImage,
        description: "Für Auto Produkte wurde mit der Full Circle App ein idealer Marketplace ähnlich Ebay-Kleinanzeigen geschaffen. Es gibt die Möglichkeit, Inserate zu erstellen, zu kaufen, mit dem Käufer oder Verkäufer im Chat Kontakt aufzunehmen und Inserate zu filtern nach Kategorie, Brand, Ort usw. Der Kauf wird ebenfalls über die App abgewickelt. Mithilfe von Stripe und Braintree kann mit Paypal oder Kreditkarte bezahlt werden. Der Betrag wird dann dem Verkäufer gutgeschrieben. Mit einem Kickback Link kann jeder Nutzer Vermittlungsprovisionen verdienen.",
        technicalInfo: `Erstellen der Projektstruktur. Initialisierung von Git (Bitbucket), Typescript und dem React-Native Framework. Anbindung an eine REST API. Entwicklung der Generischen views und des Frontends. Implementierung von Stripe, Braintree sowie Facebook, Google-SignIn und Google-Maps SDKs.
        
        Tools:
        Visual Studio Code, Android Studio, xCode, Objective-C, React-Native, JavaScript, Typescript, nodeJS, Android SDK, Pods, Jira, Github, Facebook SDK, Google SDK, Fabric, Crashlytics, Answers, Bugsnag, REST API, Google Maps, Realm, Socket.IO

        Diese App wurde fast zu ​100% von mir​ entwickelt.
        
        <a title="Petesso - Full Circle">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fpetesso.de%2Fwork%2Ffull-circle</a>
        
        Das Projekt ist noch nicht gelauncht.`,
        screens: [{
            image: FullCircleScreen1,
            description: "Einfacher und schneller Login über Facebook und Google oder nutze eine E-Mail."
        }, {
            image: FullCircleScreen2,
            description: "Finde, was du suchst, mit dem erweiterten Filter."
        }],
        date: new Date(2018, 4),
    },
    {
        title: "joinr",
        subTitle: "Explore the world together",
        image: JoinrTitleImage,
        description: "joinr ist eine App, die Event Planung einfach macht und jedem Nutzer eine komplette Übersicht über Route, Teilnehmer, Stopps und Warnungen (z.b. Blitzer) etc. gibt. Es kann unter Teilnehmern eines Events/ einer Gruppe gechattet werden oder über Kurznachrichten kommuniziert werden. Z.b. beim Klick auf den Tankstelle-Button wird an alle Teilnehmer eine Benachrichtigung mit „Ich muss Tanken“ geschickt.",
        technicalInfo: `Unterstützung bei der Frontend Entwicklung. Implementierung der REST API. Facebook, Twitter, Google Maps SDK und Crashlytics wurden implementiert.
        
        Tools:
        Android Studio, Java, Android SDK, Jira, Bitbucket, Facebook SDK, Twitter SDK, Fabric, Crashlytics, Answers, Rest API, Google Maps, Socket.IO
        
        Etwa 30%​ der ersten Android Version wurden von mir entwickelt.
        
        <a title="AppStore - joinr">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fitunes.apple.com%2Fde%2Fapp%2Fjoinr%2Fid926131432%3Fmt%3D8</a>
        <a title="PlayStore - joinr">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dde.petesso.joinr%26hl%3Dde</a>
        <a title="Website - joinr">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fjoinr.us</a>
        <a title="Petesso - joinr">https://graefenstein.skirix.com/redirect/index.php?url=https%3A%2F%2Fpetesso.de%2Fwork%2Fjoinr-explore-the-world-together</a>
        `,
        screens: [{
            image: JoinrScreen3,
            description: "Suche nach Veranstaltungen über Bluetooth, QR-Code oder das Secret."
        }, {
            image: JoinrScreen4,
            description: "Veranstalte ein eigenes Event privat oder öffentlich."
        }, {
            image: JoinrScreen2,
            description: "Die wichtigsten Informationen zu deinem Event auf einen Blick."
        }, {
            image: JoinrScreen1,
            description: "Behalte alle Teilnehmer auf der Karte im Auge."
        }],
        date: new Date(2016, 2),
    },
    /*{
        title: "ManeStreem",
        subTitle: "Beauty On-Demand",
        image: "",
        description: "Entwickelt wurde eine App, die für Friseursalons, Tattoo Studios etc. die Terminplanung, Kaufabwicklung, Bezahlung u. v. m. abnimmt. Darüber hat der Kunde die Möglichkeit mit dem Provider zu kommunizieren, als auch eine öffentliche Bewertung zu schreiben.",
        technicalInfo: "Projektstruktur planen und erstellen. Entwicklung der gesamten Frontends. Stripe wurde für die Abrechnungen implementiert. Die App wurde mit dem Facebook und Twitter SDK verknüpft. Crashlytics habe ich für das Bug Tracking verwendet.",
    },*/
]
