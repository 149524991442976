import React, { Component } from 'react';
import './me-section.css';
import { Section } from '../../interfaces/section-interface';

export default class MeSection extends Component<Section, {}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <div className="default-section-background" id={this.props.id}>
                <div className="default-section me-section">
                    <div className="profile-preview-container">
                        <div className="profile-image-ration">
                            <div className="me-profile-image-rounded"></div>
                        </div>
                        <p className="name" >ARTUR GRÄFENSTEIN</p>
                        <p className="job-title">Mobile App Developer</p>
                        <div className="personal-infos">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Mail:</td>
                                        <td>info@skirix.com</td>
                                    </tr>
                                    <tr>
                                        <td>Mobile:</td>
                                        <td>+49 176 95827227</td>
                                    </tr>
                                    <tr>
                                        <td>Wohnort:</td>
                                        <td>Marsberg</td>
                                    </tr>
                                    <tr>
                                        <td>Jahr:</td>
                                        <td>1995</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <p className="me-text" >
                        Bereits in meiner Schulzeit entwickelte ich ein großes Interesse für die digitale Welt und deren Programmierung. Informatik war immer eines meiner Lieblings Fächer und ging mir sehr leicht von der Hand. Es muss wohl Schicksal gewesen sein, 
                        als ich in dem Schüler-Wissensquiz „Live Battle” von Microsoft in der 8. Klasse ein Notebook gewonnen habe (<a href="https://www.wp.de/staedte/altkreis-brilon/ein-star-fuer-schlaue-schueler-id1079073.html" target="_blank">anschließend gewann unsere Schule den Wettbewerb und Bürger Lars Dietrich Veranstaltete eine Weihnachtsfeier in unserer Schule</a>) 
                        und seit dem endlich anfangen konnte kleine Android Apps zu entwickeln. <br />
                        Die ersten Apps waren kleine Tests um mich mit der Umgebung vertraut zu machen. Doch schnell fing ich an, für den Alltag kleine Applikationen zu schreiben wie einen KE-Rechner für Diabetiker, Time-Tracker oder kleine Mini-Spiele. 
                        So habe ich mit einem Freund neben dem Abitur unser erstes Spiel-Konzept und Design entworfen. Die Entwicklung habe ich übernommen und anschließend in den PlayStore geladen.

                        <br />
                        Später bekam ich ein Jobangebot für eine Stelle als Android App Entwickler bei der Petesso GmbH in Brilon und nahm dieses erfreut an. 
                        Während der Zeit bei Petesso konnte ich an vielen Projekten arbeiten und Erfahrungen sammeln. Die Entwicklung einiger Projekte habe ich komplett selbst übernommen und nicht nur die Android App, sondern auch die API entwickelt und die Datenbank eingerichtet. 
                        Im Laufe der Jahre habe ich an den unterschiedlichsten Projekten gearbeitet. So habe ich Erfahrung in Navigation, Chat, Audio und Video Player, Marketplaces, Android Auto, Spiele, Sensorsteuerung und vieles mehr.

                        <br />
                        Im Dezember 2018 entschied ich ein Gewerbe zu gründen und biete seit dem die Entwicklung mobiler Apps an. Wenn Sie ein qualifiziertes Unternehmen suchen, das Ihre App entwickelt, dann nehmen Sie gerne Kontakt zu mir auf. 
                        Ich entwickle Ihre App für Android und iOS zu fairen Preisen, konfiguriere die Nötigen Server Strukturen, setzte eine skalierbare Datenbank auf und entwickle für Sie eine Anwendungsschnittstelle. 
                        Bei Bedarf kann ich für Sie auch eine Webseite aufsetzten, um möglichst viele Nutzer zu erreichen. Für Ihr Projekt können Sie gerne ein fertiges Design mitbringen oder wir Designen eine moderne und intuitive App oder Webseite für Sie. 
                        Gerne berate ich Sie ausführlich und kostenlos per Telefon oder E-Mail. Gemeinsam finden wir einen Weg, wie Ihr Projekt ein Erfolg wird!
                    </p>
                </div>
            </div>
        )
    }

}
