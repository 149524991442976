import React, { Component } from 'react';
import './logo.css';
import LogoSource from '../../images/logo-graefenstein.png';


export default class Logo extends Component<{}, {}> {

    render() {
        return (
            <a href='/' >
                <img className='logo' src={LogoSource} alt='Logo Gräfenstein' />
            </a>
        );
    }

}