import React, { Component, CSSProperties } from 'react';
import './contact-section.css';
import InteractiveIcon from '../interactive-icon/interactive-icon';
import Letter from '../../images/letter.png';
import Phone from '../../images/phone.png';
import ContactForm from '../../images/contact-form.png';

export default class ContactSection extends Component<{}, {}> {

    render() {
        return (
            <div className="contact-section">
                <div className="background-circle"></div>
                <div className="contact-section-sized-container">
                    <h2>Kontakt</h2>
                    <p>
                        Nehmen Sie gerne Kontakt zu mir auf, damit wir gemeinsam einen Weg finden, <br />
                        wie Ihr Projekt ein Erfolg wird!
                    </p>
                    <div className="icons-set">
                        <InteractiveIcon src={Letter} href='mailto:info@skirix.com' alt="Write me an E-mail"/>
                        <InteractiveIcon src={Phone} href='tel:+4917695827227' alt="Call me on phone"/>
                        <InteractiveIcon src={ContactForm} href="/contact" alt="Contact me over the contact form"/>
                    </div>
                </div>
            </div>
        )
    }
}

const style: { [key: string]: CSSProperties } = {
    iconsSpace: {
        maxWidth: 130,
        flex: 1,
    }
}